import Landingpage from './components/landingpage/Landingpage'
import './App.css';

function App() {
  return (
    <div>
    <Landingpage/>
    </div>
  );
}

export default App;
